<!--
/**
 *@author clark
 *@date 2023/2/20
 *  标签 用户标签
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageTopButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageTopButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <div class="fleet-match-content">
        <YkcDetailPlus class="box-card">
          <div slot="header">
            <span>分组信息</span>
          </div>
          <div class="baseInfo">
            <div class="content">
              <div class="top">
                <div>
                  <span class="label">用户分组编码:</span>
                  <span class="value">
                    {{ dealData(detail.id) }}
                  </span>
                </div>
                <div>
                  <span class="label">用户分组名称:</span>
                  <span class="value">
                    {{ dealData(detail.groupName) }}
                  </span>
                </div>

                <div>
                  <span class="label">电站数:</span>
                  <span class="value">
                    {{ dealData(detail.stationCount) }}
                  </span>
                </div>
                <div>
                  <span class="label">创建人:</span>
                  <span class="value">
                    {{ dealData(detail.createdName) }}
                  </span>
                </div>
                <div>
                  <span class="label">创建时间:</span>
                  <span class="value">
                    {{ dealData(detail.createdTime) }}
                  </span>
                </div>
                <div>
                  <span class="label">修改人:</span>
                  <span class="value">
                    {{ dealData(detail.modifiedName) }}
                  </span>
                </div>
                <div>
                  <span class="label">修改时间:</span>
                  <span class="value">
                    {{ dealData(detail.modifiedTime) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </YkcDetailPlus>
      </div>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="电站列表"
        operateFixedType="right">
        <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { CustomDriverAccounts, stationLabelApi } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { offlineExport, dealData, code } from '@/utils';

  export default {
    name: 'CustomDriverAccountsDetail',
    components: {},
    data() {
      return {
        id: '',
        detail: {
          id: null,
          groupName: '',
          stationCount: 0,
          labelStatus: '0',
          labelStatusMean: '',
          groupDimension: '0',
          groupDimensionMean: '',
          createdBy: null,
          createdTime: '',
          modifiedBy: null,
          modifiedTime: '',
          stationList: null,
          createdName: null,
          modifiedName: null,
        },
        dictionary: {},
        headerInfo: {},
        //  流水类型

        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            enabled: () => code('group:station:detail:download'),
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '电站编码', prop: 'code', minWidth: '100px' },
          { label: '电站名称', prop: 'stationName', minWidth: '100px' },
          { label: '归属商户', prop: 'operatorName', minWidth: '100px' },
          {
            label: '电站类型',
            prop: 'stationType',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const title = row.stationType === '1' ? '直联' : '互联';
                return <span title={title}>{title}</span>;
              },
            },
          },
        ],

        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        searchParams: {
          id: this.id,
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.searchParams.id = id;
      this.getDetail();
      this.queryHeaderInfo();
      this.searchTableList();
    },
    methods: {
      dealData,
      /**
       * @function queryHeaderInfo
       * @desc 查询头部新消息
       * */
      queryHeaderInfo() {
        CustomDriverAccounts.detailHeaderInfo({ userId: this.id }).then(res => {
          this.headerInfo = res || {};
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const params = {
          ...this.searchParams,
          current: this.pageInfo.current,
          size: this.pageInfo.size,
        };
        stationLabelApi.queryStationForPage(params).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        // CustomDriverAccounts.exportOfFlowList('代理人佣金列表', this.searchParams);
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'group_station_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
      getDetail() {
        // 编辑回显
        if (this.id) {
          stationLabelApi
            .detail({ id: this.id })
            .then(res => {
              this.detail = {
                ...res,
              };
              this.pictureLicence.url = res.businessLicence;
              this.pictureStationLogo.url = res.stationLogo;
              this.picturePlatformLogo.url =
                'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281';
              this.getTreeData(true, res.configIds);
            })
            .catch(() => {});
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .fleet-match-content {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-flow: column;
    .box-card {
      // width: 50%;
      margin: 10px 0;
      .baseInfo {
        display: flex;
        font-size: 14px;
        .header {
          width: 160px;
          display: flex;
          justify-content: center;
          .head {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            img {
              width: 80px;
              height: 80px;
              border-radius: 80px;
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          .label {
            color: #9b9b9b;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            & > div {
              width: calc(100%);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }

      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 100%;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .img-label {
      margin-left: 30px;
      color: #9b9b9b;
      font-size: 12px;
      margin-right: 10px;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 10px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
    }
    .common-label-key {
      display: inline-block;
      width: 160px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      width: 124px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
